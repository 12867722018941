<template>
  <div class="flex flex-col py-2 px-2 items-center stats-count">
    <div class="metrics-header flex flex-row justify-center items-baseline">
      <p class="text-gray-500 text-sm uppercase metric-label">{{ label }}</p>
      <p class="text-sm" v-if="!hidePercentage">{{ percentage }}%</p>
    </div>
    <p class="text-blue-600 text-4xl" :class="colorCode">
      {{ count.toLocaleString() }}
    </p>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "email-metrics-card",
  props: {
    label: {
      type: String,
      required: true,
      default: ""
    },
    count: {
      type: Number,
      required: true,
      default: 0
    },
    totalCount: {
      type: Number,
      required: false,
      default: 0
    },
    colorCode: {
      type: String,
      required: false,
      default: "primary" //info, success, error
    },
    hidePercentage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    percentage() {
      if (this.totalCount === 0 || this.totalCount === 0) return 0.0;
      return ((this.count / this.totalCount) * 100).toFixed(1);
    }
  }
});
</script>

<style scoped>
.metric-label {
  margin-right: 5px;
}

.info {
  color: #67c23c;
}

.success {
  color: #e6a23c;
}

.error {
  color: #e8011885;
}

.primary {
  color: #909399;
}
</style>
